



















import {Vue, Component, Watch} from "vue-property-decorator";
import MyFriendCircleType from "@/views/Friend/MyFriendCircle/MyFriendCircle";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import FriendComponents from "@/views/Friend/components/FriendComponents/index.vue";
import {ItemType} from "@/views/Friend/FriendCircle/Store/indexType";
import {MyFriendCircleStore} from "@/views/Friend/MyFriendCircle/Store";
import {findMyFriendsCircle} from "@/views/Friend/MyFriendCircle/Server";
import ZoomPage from "@/impView/PageSafety";

@Component({name: "MyFriendCircle",components:{ HeadTop,PullDownUpList,ListBottom,FriendComponents }})
export default class MyFriendCircle extends ZoomPage implements MyFriendCircleType{
    PullDown = false
    UpDownBool = true
    StopUp = false
    loadingShow = true
    List:ItemType[] = []

    mounted(){
        let { id } = this.$route.query
        if ( id ){
            this.List = MyFriendCircleStore.getMyCircleList
            MyFriendCircleStore.setMyCircleUpData({ ...MyFriendCircleStore.getMyCircleUpData,userId:id as string })
            this.loadingShow = false
            this.handleUpData(true)
        }
    }

    handlePullDown(){
        this.PullDown = true
        MyFriendCircleStore.setMyCircleUpData({ ...MyFriendCircleStore.getMyCircleUpData,pageNo:1 })
        this.handleUpData(true)
    }

    handleUpDown(){
        if ( this.StopUp )return;
        let data = MyFriendCircleStore.getMyCircleUpData
        data.pageNo += 1
        MyFriendCircleStore.setMyCircleUpData( data )
        this.handleUpData()
    }

    handleUpData(bool=false){
        findMyFriendsCircle().then(res=>{
            this.PullDown = false
            this.UpDownBool = false
            if ( res.length < MyFriendCircleStore.getMyCircleUpData.pageSize )this.StopUp = true;
            if ( bool )
                MyFriendCircleStore.setMyCircleList( res );
            else
                MyFriendCircleStore.setMyCircleList( MyFriendCircleStore.getMyCircleList.concat(res) );
        })
    }

    get title(){
        return this.$route.query.name || "欢"
    }

    get getList(){
        return MyFriendCircleStore.getMyCircleList
    }
    @Watch("getList",{ deep:true })
    changeList(newVal:ItemType[]){
        this.List = newVal
    }
}
